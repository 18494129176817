<template>
  <div class="user-role">
    <h2>登录前，请先选择您的身份：</h2>
    <ul>
      <li
        @click="setRoleUserInfo(item)"
        v-for="(item, index) in identityList"
        :key="index"
      >
        <div>
          <img
            :src="'imgs/role' + item.identityTypeCode + '.png'"
            alt=""
            srcset=""
          />
        </div>
        <p>{{ item.identityName }}</p>
        <p style="margin-top: -8px">
          <span style="font-size: 16px; color: rgb(70, 183, 211)"
            >({{ item.authStatus==1?'已认证':'未认证' }})</span
          >
        </p>
      </li>
    </ul>
  </div>

</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { Utils } from "@/assets/js/util.js";
import { getUserMenuByIdentity, getIdentityList } from "@/api/add";
import { UnreadMessage } from "@/api/websocket";
import { log } from "console";
// import { debug } from 'console';
export default {
  data() {
    return {
      identityList: [],
      ws: null,
      leave_page: false,
      is_first_in_page: true, // 判断是否是第一次进入页面，在 websocket 连接成功时判断是否调用子路由
    };
  },
  computed: {
    ...mapGetters([
      "identityObj",
      "userInfo",
      "vipLeftTree",
      "topSelectAreacode",
      "AllMessages",
      "menuList",
    ]),
  },
  watch: {},
  created() {
    this.loadIdentityList();
  },
  methods: {
    ...mapMutations(["setVipTree", "SET_MESSAGE"]),
    //调用header页面的方法
    functionA() {
      Utils.$emit("demo", "msg");
    },

    async UnMessage() {
      let params = {
        receiverId: this.identityObj.identityId,
      };
      let res = await UnreadMessage(params);
      let AllMessages = res.data;
      this.SET_MESSAGE(AllMessages);
    },

    async loadIdentityList() {
      let res = await getIdentityList();
      console.log(res);
      if (res.code == 0) {
        this.identityList = res.data;
      }
    },
    transMenu(tree, parentName = "") {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        const fullName = parentName + "/" + node.path; // 拼接全称
        if (node.children) {
          this.transMenu(node.children, fullName); // 递归调用函数遍历子节点
        }
        node.allPath = fullName; // 赋值全称给节点
      }
      return tree;
    },
    async getOthersInfo() {
      console.log(this.menuList);

      let res = await getUserMenuByIdentity();
      if (res.code == 0 && this.menuList.length > 0) {
        // localStorage.setItem('identityObj', JSON.stringify(res.data.identity)); // 对象需先转换为 JSON 字符串
        res.data.menuList.forEach((item) => {
          if (item.path === "memberCenter") {
            let arr = this.transMenu([item][0].children, "/memberCenter");
            // 在这里调用header页面的方法
            this.functionA();

            if (arr.length > 0) {
              this.setVipTree(arr);
              this.$router.$avueRouter.formatRoutes(arr, true);
              if (arr[0].children.length == 0) {
                this.$router.push({
                  path: arr[0].allPath,
                });
              } else {
                if (arr[0].children.length > 0) {
                  this.$router.push({
                    path: arr[0].children[0].allPath,
                  });
                }
              }
            }
          }
        });
      }
    },
    setRoleUserInfo(item) {
      console.log(item);
      if (item.authStatus == 1) {
        this.$store.dispatch("SetIdentityObj", item);
        this.getOthersInfo();
        this.UnMessage()
        console.log("222222222222222222222222");
      } else {
        this.$store.dispatch("SetIdentityObj", item);
   if (item.identityTypeCode == 2) {
    this.$router.push({ name: "register1", params: { item: item } });
} else if (item.identityTypeCode == 4) {
    this.$router.push({ name: "register3", params: { item: item } });
} else if (item.identityTypeCode == 3) {
    this.$router.push({ name: "register2", params: { item: item } });
}

      }
    },
  },
};
</script>

<style lang="less" scoped>
.user-role {
  text-align: center;
  height: 100%;
  width: 100%;
  min-height: 650px;
  position: relative;
  z-index: 3;
  background-image: url("/imgs/roleBg.png");
  background-position: 0 45%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  h2 {
    padding-top: 120px;
  }
  ul {
    width: 1470px;
    margin: 90px auto;
    margin-bottom: 0;
    display: flex;
    justify-content: space-around;
    li {
      width: 220px;
      height: 220px;
      background: #fafafa;
      border: 6px solid transparent;
      border-radius: 50%;
      list-style: none;
      cursor: pointer;
      font-size: 22px;
      color: #333333;
      padding-top: 35px;
      box-sizing: border-box;
      > div {
        display: flex;
        justify-content: center;
        align-self: center;
        min-height: 80px;
        img {
          height: 70px;
        }
      }
      p {
        margin: 6px 0;
      }
    }
    li:hover {
      border: 6px solid #0668b3;
      color: #0668b3;
    }
  }
}
</style>
